import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import toElement from 'plugins/element/to_element';

function fire(element, name, args) {
  const e = toElement(element);
  let bubbles;
  let cancelable;
  let detail;

  if (isVariableDefinedNotNull(args)) {
    bubbles = args.bubbles;
    cancelable = args.cancelable;
    detail = args.detail;
  }

  bubbles = isVariableDefinedNotNull(bubbles) ? bubbles : true;
  cancelable = isVariableDefinedNotNull(cancelable) ? cancelable : true;
  detail = isVariableDefinedNotNull(detail) ? detail : {};

  let event;

  try {
    event = new CustomEvent(name, {
      bubbles,
      cancelable,
      detail,
    });
  } catch (error) {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, bubbles, cancelable, detail);
  }

  e.dispatchEvent(event);

  return !event.defaultPrevented;
}

export default fire;
